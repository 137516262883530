





















































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

@Component
export default class AboutUs extends Mixins(View) {}
